<template>
  <div class="row my-1">
    <div class="col-6 text-end">{{ amountAndUnit }}</div>
    <div class="col-6 text-start fw-bold">{{ title }}</div>
  </div>
</template>

<script>
export default {
  props: ["amount", "unit", "title"],
  computed: {
    amountAndUnit() {
      return this.amount ? this.amount + " " + this.unit : "";
    },
  },
};
</script>

<style scoped></style>

<template>
  <div class="row my-1" :aria-label="`Zubereitungsschritt: ${step.itemOrder}`">
    <div class="col-2 text-red">{{ step.itemOrder }}</div>
    <div class="col-10" v-html="stepAsMarkdown"></div>
  </div>
</template>

<script>
import marked from "marked";
export default {
  props: ["step"],
  computed: {
    stepAsMarkdown() {
      // console.log(this.step);
      return marked(this.step.step);
    },
  },
};
</script>

<style lang="scss" scoped></style>

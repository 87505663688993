<template>
  <base-bg>
    <div>
      <add-ingredients-dialogue
        ref="addIngredientsDialgue"
      ></add-ingredients-dialogue>
      <top-nav-bar></top-nav-bar>

      <div class="page" v-if="recipe">
        <section class="container mb-5">
          <div style="position: relative">
            <base-like
              :item="getRecipe"
              :large="true"
              :allowLike="true"
            ></base-like>
            <img
              :src="getRecipe.path"
              alt=""
              class="main-picture"
              :aria-label="getRecipe.title"
            />
          </div>

          <h1 class="text-center mt-4 mb-2 text-handwriting">
            {{ getRecipe.title }}
          </h1>
          <p class="text-muted text-center">
            {{ seasonDurationOfRecipe }}
          </p>

          <div class="row justify-content-around align-itemx-center my-3">
            <recipe-tag
              class="col-3"
              image="duration.svg"
              :content="getRecipe.duration + ' min'"
            ></recipe-tag>
            <div class="d-flex justify-content-center align-items-center col-6">
              <div class="portion-button left" @click="decreasePortions">
                <minus-icon :size="32" fillColor="#db7768"></minus-icon>
              </div>
              <recipe-tag
                class=""
                image="soup.svg"
                :content="getRecipe.portions + ' Port.'"
              ></recipe-tag>
              <div class="portion-button right" @click="increasePortions">
                <plus-icon :size="32" fillColor="#db7768"></plus-icon>
              </div>
            </div>

            <recipe-tag
              class="col-3"
              image="leaf.svg"
              :content="recipeTag"
            ></recipe-tag>
          </div>
          <div class="text-center mt-4">
            <div class="mx-2">
              <span class="description fst-italic text-libre">{{
                getRecipe.description
              }}</span>
            </div>
          </div>
          <div class="row mt-3">
            <span
              v-if="!getRecipe.shop || getRecipe.shop == 0"
              class="text-uppercase text-center author"
              aria-label="der Autor"
            >
              {{ getRecipe.author }}
            </span>
            <router-link
              :to="{
                name: $routeNames.routeMapItemPage,
                params: { id: shopId },
              }"
              v-else
              class="text-center"
              aria-label="der Autor"
              ><span class="text-uppercase text-center border-bottom author">{{
                getRecipe.author
              }}</span>
            </router-link>
          </div>
        </section>
        <section class="container mt-5 col-10 mx-auto position-relative">
          <h2 class="text-center my-3 fs-6">ZUTATEN</h2>
          <recipe-ingredient
            v-for="ingredient in getRecipe.ingredients"
            :key="ingredient.id"
            :amount="ingredient.amount"
            :title="ingredient.title"
            :unit="ingredient.unit"
          ></recipe-ingredient>
          <div class="export-wrapper mt-3">
            <div @click="onExport">
              <img
                src="../../../assets/images/icons/shopping_cart.svg"
                alt=""
              />
            </div>
            <p class="custom-link" @click="onExport">Auf meine Einkaufsliste</p>
          </div>
        </section>
        <base-divider class="mt-3 mb-5"></base-divider>
        <section class="container col-10 mx-auto my-5">
          <h2 class="text-center my-3 fs-6">ZUBEREITUNG</h2>
          <recipe-step
            v-for="step in getRecipe.steps"
            :key="step.itemOrder"
            :step="step"
          ></recipe-step>
          <div class="row my-1" v-if="getRecipe.source != ''">
            <div class="col-2"></div>
            <div class="col-10">
              <a
                :href="getRecipe.cleanLink"
                target="_blank"
                class=""
                v-if="getRecipe.cleanLink != ''"
                >Quelle: <u>{{ getRecipe.cleanLinkAuthor }}</u></a
              >
              <p v-else>Quelle: {{ getRecipe.source }}</p>
            </div>
          </div>
          <div class="row my-1" v-if="getRecipe.photorights != ''">
            <div class="col-2"></div>
            <div class="col-10">
              <p>Abbildung: {{ getRecipe.photorights }}</p>
            </div>
          </div>
        </section>
        <div class="container my-4" v-if="$connector.platform != 'web'">
          <base-share-button :text="getRecipe.title"></base-share-button>
        </div>
      </div>
    </div>
  </base-bg>
</template>

<script>
import PlusIcon from "vue-material-design-icons/Plus.vue";
import MinusIcon from "vue-material-design-icons/Minus.vue";
import RecipeTag from "./components/RecipeTag.vue";
import RecipeIngredient from "./components/RecipeIngredient.vue";
import RecipeStep from "./components/RecipeStep.vue";
import BaseLike from "../../../components/UI/BaseLike.vue";
import AddIngredientsDialogue from "./components/AddIngredientsDialog.vue";
import { mapGetters, mapActions } from "vuex";
import BaseDivider from "../../../components/UI/BaseDivider.vue";
import converter from "../../../assets/helpers/converter";

export default {
  components: {
    RecipeTag,
    RecipeIngredient,
    RecipeStep,
    BaseDivider,
    BaseLike,
    AddIngredientsDialogue,
    PlusIcon,
    MinusIcon,
  },
  props: ["id"],
  inject: ["activePage"],
  data() {
    return {
      recipe: null,
    };
  },
  computed: {
    ...mapGetters(["getRecipe"]),
    seasonDurationOfRecipe() {
      return this.getRecipe
        ? converter.getSeasonDurationOfIngredients(this.recipe.season)
        : "";
    },
    recipeTag() {
      if (!this.getRecipe.tags) {
        return "mit Fleisch";
      }
      const tag = this.getRecipe.tags[0];
      if (tag == 1) {
        return "Vegan";
      } else if (tag == 2) {
        return "Vegetarisch";
      } else {
        return "Mit Fleisch";
      }
    },
    shopId() {
      console.log(this.getRecipe.shop);
      return this.getRecipe.shop ? this.getRecipe.shop : 0;
    },
  },
  methods: {
    ...mapActions([
      "loadRecipe",
      "exportRecipeToShoppingList",
      "resetShoppingList",
      "increasePortions",
      "decreasePortions",
    ]),

    async updateRecipe() {
      console.log("loadRecipe: " + this.id);
      await this.loadRecipe(this.id);
      const recipe = this.getRecipe;
      if (!recipe) {
        console.log("no recipe found");
        this.$router.push({ name: this.$routeNames.routeRecipesList });
        return;
      }
      this.recipe = recipe;
      console.log(recipe);
    },
    onExport() {
      this.$refs.addIngredientsDialgue.show();
    },
  },
  created() {
    this.activePage.value = this.$routeNames.routeRecipePage;
    this.updateRecipe();
  },
};
</script>

<style scoped>
a,
a:hover {
  color: black;
}

.description::before {
  content: url("../../../assets/images/icons/quote.svg");
  position: relative;
  top: 0.2rem;
  margin-right: 3px;
}

.description::after {
  content: url("../../../assets/images/icons/quote.svg");
  position: relative;
  top: -0.3rem;
  margin-left: 1px;
}

.export-wrapper {
  display: flex;
  flex-direction: column;
  color: var(--main-color-red);
  align-items: center;
}

.export-wrapper img {
  width: 40px;
}

.portion-button {
  display: flex;
  height: 25px;
  padding: 3px;
  justify-content: center;
  align-items: center;
  color: var(--main-color-red);
  align-self: end;
}

.portion-button.left {
  margin-right: 15px;
}
.portion-button.right {
  margin-left: 15px;
}
</style>

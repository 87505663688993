<template>
  <div class="wrapper">
    <img :src="require(`@/assets/images/icons/${image}`)" class="" />
    <span class="text-center d-block">{{ content }}</span>
  </div>
</template>

<script>
export default {
  props: ["image", "content"],
  computed: {
    imagePath() {
      return "../../../../assets/images/icons/" + this.image;
    },
  },
};
</script>

<style scoped>
.wrapper {
  line-height: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 50px;
}

span {
  font-size: 0.8rem;
  margin-top: 14px;
}

@media only screen and (min-width: 768px) {
  span {
    font-size: 1rem;
  }
}

img {
  width: 30px;
}
</style>

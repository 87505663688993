<template>
  <transition name="fade">
    <div class="popup-modal" v-if="isVisible">
      <div class="window">
        <div class="cancel" @click="cancel">
          <close-icon :size="16" fillColor="#6c757d"></close-icon>
        </div>
        <h2 class="text-handwriting text-red text-center" style="margin-top: 0">
          Auf meine Einkaufsliste
        </h2>
        <p>
          Füge die Zutaten des Rezepts Deiner Einkaufsliste hinzu oder ersetze
          die gesamte Einkaufsliste.
        </p>
        <p>
          Du findest Deine Einkaufsliste immer im seitlichen Menü, einfach oben
          rechts auf "..." klicken.
        </p>
        <div class="portion-row">
          <div class="portion-button" @click="decrease">
            <minus-icon :size="16"></minus-icon>
          </div>

          <div class="col-5 text-center row align-items-center">
            <div
              class="col-12 d-flex align-items-center justify-content-center"
            >
              <h3 class="portion-title">{{ portions }}</h3>
            </div>
            <div
              class="col-12 d-flex align-items-center justify-content-center"
            >
              <span class="">Portionen</span>
            </div>
          </div>
          <div class="portion-button" @click="increase">
            <plus-icon :size="16"></plus-icon>
          </div>
        </div>
        <div class="d-flex justify-content-between">
          <div class="portion-row cart" @click="add">
            <div
              class="col-12 d-flex align-items-center justify-content-center"
            >
              <img
                src="../../../../assets/images/icons/shopping_cart.svg"
                alt=""
              />
            </div>
            <div
              class="col-12 d-flex align-items-center justify-content-center"
            >
              <span class="">Hinzufügen</span>
            </div>
          </div>
          <div class="portion-row cart" @click="replace">
            <div
              class="col-12 d-flex align-items-center justify-content-center"
            >
              <img
                src="../../../../assets/images/icons/shopping_cart_reload.svg"
                alt=""
              />
            </div>
            <div
              class="col-12 d-flex align-items-center justify-content-center"
            >
              <span class="">Ersetzen</span>
            </div>
          </div>
        </div>
        <div class="portion-links">
          <router-link
            :to="{ name: $routeNames.routeShoppingListPage }"
            class="d-block text-red text-center fs-6 custom-link my-3"
          >
            <span>Einkaufsliste ansehen</span></router-link
          >
          <p class="text-muted" @click="cancel">Schließen</p>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import PlusIcon from "vue-material-design-icons/Plus.vue";
import MinusIcon from "vue-material-design-icons/Minus.vue";
import CloseIcon from "vue-material-design-icons/Close.vue";
export default {
  components: { PlusIcon, MinusIcon, CloseIcon },
  data() {
    return {
      isVisible: false,
      portions: null,
    };
  },
  computed: {
    ...mapGetters(["getRecipe"]),
  },
  methods: {
    ...mapActions([
      "resetShoppingList",
      "exportRecipeToShoppingList",
      "changeRecipePortionsTo",
      "decreasePortions",
    ]),
    decrease() {
      this.portions = this.portions - 1 > 0 ? this.portions - 1 : 0;
    },
    increase() {
      this.portions = this.portions + 1;
    },
    cancel() {
      this.close();
    },
    add() {
      this.close();
      this.export();
    },
    replace() {
      this.close();
      this.resetShoppingList();
      this.export();
    },
    export() {
      this.changeRecipePortionsTo(this.portions);
      this.exportRecipeToShoppingList();
    },
    show() {
      this.isVisible = true;
      this.portions = parseInt(this.getRecipe.portions);
    },
    close() {
      this.isVisible = false;
    },
  },
};
</script>

<style scoped>
.portion-row {
  width: 100%;
  background-color: var(--main-color-yellow-light);
  border-radius: 5px;
  padding: 10px;
  margin: 10px 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 70px;
}
.portion-row.cart {
  width: 45%;
  flex-direction: column;
}

.portion-row img {
  width: 35px;
}

.portion-title {
  margin: 0px;
  line-height: 1;
}

.portion-button {
  display: flex;
  width: 40px;
  height: 40px;
  padding: 5px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: white;
}

.portion-links {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.btns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-width: 200px;
}
/* css class for the transition */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.popup-modal {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  z-index: 500;
}

.window {
  background: #fff;
  border-radius: 5px;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 480px;
  margin-left: auto;
  margin-right: auto;
  padding: 1.5rem;
  z-index: 501;
  position: relative;
}

.window .cancel {
  position: absolute;
  top: 10px;
  right: 10px;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>